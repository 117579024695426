import React from "react";
import { useState } from "react";
import { Modal } from "reactstrap";
import { browserName } from "react-device-detect";
import { useEffect } from "react";

const ChromePopUp = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log(browserName);
    if (browserName !== "Chrome") {
      setIsOpen(true);
    }
  }, []);

  const close = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        size="lg"
        id="anouncementPopup"
        centered
        style={{ maxWidth: "460px" }}
      >
        <div className="text-left">
          <div className="modal-header pb-0" hidden="">
            <div>
              <h4
                className="mt-0"
              >
                <div>
                  <svg
                    style={{ color: "rgb(152, 203, 54)", width: "20%" }}
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#98cb36"
                      d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"
                    ></path>
                  </svg>{" "}
                  <br />
                </div>
                <a
                  onClick={close.bind(this)}
                  style={{
                    color: "#93c13e",
                    opacity: "unset",
                    position: "absolute",
                    top: "4%",
                    right: "3%",
                  }}
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
              </h4>
            </div>
          </div>
          <div
            className="modal-body pte_popup_modal_body pt-0"
            style={{ fontSize: "15px" }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600" }} className="text-center mb-2">BROWSER NOT SUPPORTED</div>
            <p
              className="mb-0"
              style={{ fontSize: "small", textAlign: "justify" }}
            >
              For optimal performance and to ensure the best user experience, we
              recommend using{" "}
              <a target="blank" href="https://www.google.com/chrome/">Google Chrome</a> as your
              browser. Please switch to
              <a target="blank" href="https://www.google.com/chrome/"> Google Chrome</a> for
              full support and seamless utilization of all features, including
              AI capabilities.
            </p>

            {/* <div className="mb-1">
              <b>For Online Classes:</b>
            </div>

            <p className="mb-0">
              <i className="fa fa-mobile" style={{ fontSize: "large" }}></i> :
              +977 71591305 <br />
            </p>
            <p>
              <i className="fa fa-whatsapp"></i> : +977 9857056995 <br />
            </p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChromePopUp;
