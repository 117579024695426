import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import Sound from 'react-sound';
import API, { s3base } from 'api.js';
import SelectOption from "components/PracticeMaterial/SelectOption";

import listenAudioImage from "assets/images/listen-audio.png";
const availablePlaybackRates = [
	{
		label: `1 x`,
		value: 1
	},
	{
		label: `1.2 x`,
		value: 1.2
	},
	{
		label: `1.5 x`,
		value: 1.5
	}
];

export default function ListenAudio(props) {
	let { isOpen, toggle, question, question_id, voices, audios } = props;

	const [selectedVoice, setSelectedVoice] = useState("Select Audio Voice");
	const [audioUrl, setAudioUrl] = useState(null);
	const [lastPlayed, setLastPlayed] = useState(null);
	const [selectedSpeed, setSelectedSpeed] = useState(availablePlaybackRates[0]);
	const [speedOptions, setSpeedOptions] = useState(availablePlaybackRates);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [soundStatus, setSoundStatus] = useState(Sound.status.PLAYING);
	const [availableVoices, setAvailableVoices] = useState([]);

	useEffect(() => {

		filterVoiceList();

	}, []);

	useEffect(() => {
		if (audios.length > 0) {
			const selected = audios.filter((audio) => {
				return audio.label === selectedVoice;
			});

			if (selected.length > 0) {
				const audio_url = selected[0].value;
				const full_url = `${s3base}/${audio_url}`;
				
				setAudioUrl(full_url);
				setLastPlayed(full_url);
				setSoundStatus(Sound.status.PLAYING);
			}
		}
	}, [selectedVoice]);

	useEffect(() => {
		if (availableVoices.length > 0) {
			selectDefaultVoice();
		}
	}, [availableVoices]);


	const selectDefaultVoice = () => {
		let x = parseInt(Math.random() * availableVoices.length);
		selectVoice(availableVoices[x].value);
	}


	const filterVoiceList = async () => {
		let filtered_voices = [];
		if (voices.length > audios.length) {
			await audios.map((audio) => {
				filtered_voices.push({
					label: audio.label,
					value: audio.label
				});
			});
			setAvailableVoices(filtered_voices);
		} else {
			setAvailableVoices(voices);
		}
	}

	const selectVoice = (selectedValue) => {
		setSelectedVoice(selectedValue);
		
		/**
		 * Not using this block on code now.
		 * Fetching audios while loading question.
		 *
		setAudioUrl(null);

		API({
			url: 'generate/aiaudio',
			method: 'POST',
			data: {
				voice: selectedValue,
				question_id: question_id
			}
		}).then((res) => {
			const { audio_url } = res.data;
			const full_url = `${s3base}/${audio_url}`;
			setAudioUrl(full_url);
			setLastPlayed(full_url);
			
		}).catch((err) => {
			console.log("error", err);
		});
		*/
	}

	const finishStopped = () => {
		// setAudioUrl("");
		setSoundStatus(Sound.status.STOPPED);
		setAudioPlaying(!audioPlaying);
	}

	const selectSpeed = (speed) => {
		const selected = speedOptions.filter((option) => {
			return option.value === speed;
		});
		setSelectedSpeed(selected[0]);
	}

	const playPauseButton = () => {
		setSoundStatus(audioPlaying ? Sound.status.PAUSED : Sound.status.PLAYING);
		setAudioPlaying(!audioPlaying);
	}

	const audioLoaded = (obj) => {
		if (obj.loaded) {
			setAudioPlaying(true);
			// setSoundStatus(Sound.status.PLAYING);
		}
	}

	const renderPlayPauseButton = () => {
		if (audioUrl === null || audioUrl === "") {
			return "";
		} else {
			return (
				<i
					className={`fa fa-${audioPlaying ? "pause" : "play"} play-pause-button`}
					onClick={playPauseButton}
				>{audioPlaying ? "PAUSE" : "PLAY"}</i>
			);
		}
		return "";
	}

	const popupTitle = () => {
		const { subcategory_id } = props;

		let heading = "";
		switch(parseInt(subcategory_id)) {
			case 1:
				heading = "Sample Response";
				break;
			case 11:
				heading = "Hearing Mode";
				break;
		}

		return heading;
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			backdropClassName="shadow-backdrop"
			className="practice-shadowing-modal listen-audio-modal"
		>
			<ModalBody>
				<div className="container-fluid">
					<img className="listen-audio-icon" src={listenAudioImage} alt="listen Audio icon" />
					<div className="listen-audio">
						<h4 className="mb-2">{popupTitle()}</h4>
						<div className="row">
							<div className="col-lg-4">
								{availableVoices.length > 0 &&
									<SelectOption
										isClearable={false}
										selected={selectedVoice}
										data={availableVoices.filter((audio, i) => {
											return audio.value !== selectedVoice
										})}
										changeEvent={selectVoice}
										placeholder={selectedVoice}
									/>
								}
							</div>
							<div className="col-lg-2">
								{availableVoices.length > 0 &&
									<SelectOption
										isClearable={false}
										selected={selectedSpeed.label}
										data={speedOptions.filter((speed, i) => {
											return speed.label !== selectedSpeed.label
										})}
										changeEvent={selectSpeed}
										placeholder={selectedSpeed.label}
									/>
								}
							</div>
							<div className="col-lg-2">
								{renderPlayPauseButton()}
							</div>
						</div>
						<div className="mt-3 mb-2 ques-text">{question}</div>
						<div className="sound-container" style={{ display: 'none'}}>
							{audioUrl !== null &&
								<Sound
									url={audioUrl}
									onLoad={audioLoaded}
									playStatus={soundStatus}
									onFinishedPlaying={finishStopped}
									playbackRate={selectedSpeed.value}
								/>
				            }
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					className="close-button"
					onClick={() => toggle(false)}
				>Close</Button>
			</ModalFooter>
		</Modal>
	);
}